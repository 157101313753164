<template>
  <div class="divBox">
    <el-card class="mt14">
      <el-table :data="tableData.data" size="small" highlight-current-row>
         <el-table-column
          type="index"
          :index="getTabelIndex"
          width="100"
          label="序号"
        />

        <!-- <el-table-column prop="id" label="ID" min-width="120" /> -->
        <el-table-column prop="nickname" label="用户名称" min-width="120" />
        <el-table-column label="头像" min-width="150">
          <template slot-scope="scope">
            <div class="demo-image__preview" >
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.avatarUrl||moren"
                :preview-src-list="[scope.row.avatarUrl||moren]"
              />
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="phone" label="手机号" min-width="120" />

        <el-table-column label="操作" min-width="120" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-setting"
              size="small"
              @click="exportFun(scope.row.id)"
              >导出</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
import { serviceChatList } from "@/api/service/service";
import { getIndex } from "@/utils/other";
import { exportExcel } from "@/assets/utils/exportExcel";
import moren from "@/assets/images/moren.png";
export default {
  name: "chatTable",
  data() {
    return {
      moren,
      title: "新增",
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
      },
      form: { 
        image: [],
        sellPrice: "",
        receivePrice: "",
        sort: "",
      },
      tableData: {
        data: [{}],
        total: 0,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getTabelIndex(index) {
      return getIndex(index, this.tableFrom.pageNum, this.tableFrom.pageSize);
    },
    uploadDelete() {},
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadAfter(res, done) {
      done();
    },
    upload(e) {
      console.log("123", e);
      if (Array.isArray(e)) {
        this.form.image = [...e];
      } else {
        this.form.image = [...this.form.image, e];
      }
    },
    getList() {
      this.listLoading = true;
      serviceChatList(this.tableFrom) 
        .then((res) => {
          this.tableData.data = res.data;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    exportFun(id){
      // window.open(`http://localhost:8080/api/user/web/service/chat/user/export/${id}`,'_blank');
      window.location.href = `${location.origin}/api/user/web/service/chat/user/export/${id}`
      // exportExcel(`/user/web/service/chat/user/export/${id}`, {
      //   responseType: "blob",
      //   exportExcelName: "聊天记录",
      // });
    },
  }
};
</script>
  
<style lang="scss" scoped>
.changeLink {
  width: 200px;
  height: 30px;
  border: 1px solid #dcdfe6;
  line-height: 30px;
  font-size: 14px;
  padding-left: 14px;
  box-sizing: border-box;
  cursor: pointer;
}
.inputWidth {
  width: 300px;
}
.inputBox {
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
  