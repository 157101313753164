import { get, put,Delete,post } from '../axios'

// 客服列表-列表
export const serviceList = params => get(`/user/web/service/manage/list`,params);

// 客服列表-编辑
export const serviceUp = params => put(`/user/web/service/manage/update`,params);

// 客服列表-聊天记录-列表
export const serviceChatList = params => get(`/user/web/service/chat/user/list`,params);

// 客服列表-聊天记录-导出
export const serviceChatListExport = params => get(`/user/web/service/chat/user/export/${params}`,params);



